import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import MemoryGameFooterList from "./MemoryGameFooterList";

function MemoryGameFooter({
  changeSpeed,
  setChangeSpeed,
  setPlayVariant,
  chooseVoc,
  setChooseVoc,
}) {
  return (
    <div className={styles.footer}>
      <ul className={styles.footerListParent}>
        <MemoryGameFooterList
          chooseVoc={chooseVoc}
          setChooseVoc={setChooseVoc}
          changeSpeed={changeSpeed}
          setChangeSpeed={setChangeSpeed}
          setPlayVariant={setPlayVariant}
        />
      </ul>
    </div>
  );
}

export default MemoryGameFooter;
