import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";
export default function MultyplayerHeader() {
  const history = useHistory();
  let room = window.location.search.split("=");
  console.log(room.reverse());
  return (
    <div className={style.header}>
      {sessionStorage.user1&&<div>
        <h5>{sessionStorage.user1}</h5>
        <div>
            Score:<div></div>
        </div>
      </div>}
      {sessionStorage.user2&&<div>
        <h5>{sessionStorage.user2}</h5>
        <div>
            Score:<div></div>
        </div>
      </div>}
    </div>
  );
}
