import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import compare from "../../assets/img/compare.svg";

function MemoryGameFooterList({
  changeSpeed,
  setChangeSpeed,
  setPlayVariant,
  chooseVoc,
  setChooseVoc,
}) {
  let [activeSpeed, setActiveSpeed] = useState("standard");
  let changeSpeedHandler = (state) => {
    changeSpeed(state);
  };
  return (
    <>
      <li className={styles.footerList}>
        <div className={styles.footerListTitle}>Game mode:</div>
        <div className={styles.footerListCards}>
          <div className={styles.card} onClick={() => setPlayVariant(0)}>
            Solo
          </div>
          <div className={styles.card} onClick={() => setPlayVariant(1)}>
            With a friend
          </div>
          <div className={styles.card}>Against computer</div>
        </div>
      </li>
      <li className={styles.footerList}>
        <div className={styles.footerListTitle}>Speed:</div>
        <div className={styles.footerListCards}>
          <div
            className={changeSpeed === "Fast" ? styles.selected : styles.card}
            onClick={() => setChangeSpeed("Fast")}
          >
            Fast
          </div>
          <div
            className={
              changeSpeed === "Standard" ? styles.selected : styles.card
            }
            onClick={() => setChangeSpeed("Standard")}
          >
            Standard
          </div>
          <div
            className={changeSpeed === "Slow" ? styles.selected : styles.card}
            onClick={() => setChangeSpeed("Slow")}
          >
            Slow
          </div>
        </div>
      </li>
      <li className={styles.footerList}>
        <div className={styles.footerListTitle}>Vocabulary list:</div>
        <div className={styles.footerListCards}>
          {/* <div className={styles.card}>Vocabulary List 1</div> */}
          <div className={styles.selected}>New vocabulary list</div>
        </div>
      </li>
      <li className={styles.footerList}>
        <div className={styles.footerListTitle}>Language:</div>
        <div className={styles.footerListCards}>
          <select
            name=""
            id="tr1"
            className={styles.selected}
            defaultValue={chooseVoc}
            onChange={(e) => setChooseVoc(e.target.value)}
          >
            <option value="eng" defaultValue>
              English
            </option>
            <option value="ger">German</option>
          </select>
          <div>
            <img src={compare} alt="compare" />
          </div>
          <select
            name=""
            id="tr2"
            className={styles.card}
            defaultValue={chooseVoc}
            onChange={(e) => setChooseVoc(e.target.value)}
          >
            <option value="ger">German</option>
            <option value="eng">English</option>
          </select>
        </div>
      </li>
    </>
  );
}

export default MemoryGameFooterList;
