import React, { useEffect, useState } from "react";
import styles from "./style.module.css";
import MemoryGameHeader from "./MemoryGameHeader";
import MemoryGameFooter from "./MemoryGameFooter";
import "./style.css";
import MultyplayerHeader from "../MultyplayerHeader";
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import SmallModal from "./SmallModal";
const ENDPOINT = "https://backend.sofamaster.am/";
const socket = socketIOClient(ENDPOINT);

function MemoryGame() {
  const [openedCard, setOpenedCard] = useState([]);
  const [matched, setMatched] = useState([]);
  const [play, setPlay] = useState(false);
  const [steps, setSteps] = useState(1);
  const [count, setCount] = useState(0);
  const [playVariant, setPlayVariant] = useState(0);
  const [changeSpeed, setChangeSpeed] = useState("Standard");
  const [chooseVoc, setChooseVoc] = useState("eng");
  const { ids } = useParams();
  const [open, setOpen] = useState(false);
  const [pairOfTurtles, setPairOfTurtles] = useState(null);
  const history = useHistory();
  let room = window.location.search.split("=");
  useEffect(() => {
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      return (event.returnValue =
        "Are you sure you want to exit? All data will be lost");
    };

    window.addEventListener("beforeunload", beforeUnloadListener);
    socket.on("updatedGamers", (data) => {
      console.log(data, "ooooooooooooooo");
      sessionStorage.user1 = data.user1;
      sessionStorage.user2 = data.user2;
    });
  }, []);

  useEffect(() => {
    if (playVariant === 1 && !window.location.search) {
      let user1 = prompt("enter your email");
      if (!user1) {
        alert("Room wasn't joined");
      } else {
        sessionStorage.currentUser = user1;
        sessionStorage.user1 = user1;
        (async () => {
          await socket.emit("createRoom", {
            user1: user1,
          });
          await socket.on("createdRoom", ({ room }) => {
            console.log(room);
            history.push(`?room=${room}`);
            alert(`copy this link: ${window.location.href}&invite`);
            console.log(room, "-------client.id");
          });
        })();
      }
    } else if (playVariant !== 1 && window.location.search) {
      setPlayVariant(1);
      if (window.location.search.includes("invite")) {
        if (room.length > 2) {
          return;
        } else {
          let user2 = prompt("enter your email");
          if (!user2) {
            alert("Room wasn't joined");
          } else {
            
              history.push(`${window.location.search}=${user2}`);
              // if (room.length > 2) {
              console.log(room[1].split("&"));
              socket.emit("joinRoom", {
                room: room[1].split("&")[0],
                user2: user2 || room[2],
              });
              socket.on("updatedGamers", (data) => {
                sessionStorage.user1 = data.user1;
                sessionStorage.user2 = data.user2;
                console.log(data, "updated-gamers");
              });
              // }
           
          }
        }
      }
      // let user2 = prompt("enter your email");
      // if (!user2) {
      //   alert("Room wasn't joined");
      // } else {
      //   console.log(user2, room[1]);
      //   sessionStorage.currentUser = user2;
      //   socket.emit("joinRoom", { room: room[1], user2: user2 });
      //   socket.on("updated-gamers", (data) => {
      //     sessionStorage.user1 = data.user1;
      //     sessionStorage.user2 = data.user2;
      //     console.log(data, "updated-gamers");
      //   });
      // }
    }
  }, [playVariant]);

  useEffect(() => {
    axios
      .get(
        `https://2321.freelancedeveloper.site/services/VocabulariesService.php?url=get&id=${ids}`
      )
      .then((r) => {
        const randomizer = [];
        r.data.map((item) => {
          if (item.words) {
            randomizer.push(JSON.parse(item.words));
          }
        });

        const randomed = Object.assign(...randomizer);
        let tr1 = document.getElementById("tr1").value;
        let tr2 = document.getElementById("tr2").value;
        let arr = [].slice(0, 10);
        let index = 0;
        for (const [key, value] of Object.entries(randomed)) {
          if (tr2 == "eng") {
            if (index < 10) {
              arr.push({ id: key, name: key });
              index++;
            }
          } else {
            if (index < 10) {
              arr.push({ id: key, name: key });
              arr.push({ id: key, name: value });
              index++;
            }
          }
        }
        if (tr2 == "eng") {
          setPairOfTurtles([...arr, ...arr]);
        } else {
          setPairOfTurtles([...arr]);
        }
      });
  }, [chooseVoc]);

  const activeSpeedStyle = () => {
    switch (changeSpeed) {
      case "Fast":
        return "0.3s cubic-bezier(0.38, 0.02, 0.09, 1.66) all";
      case "Standard":
        return "0.6s cubic-bezier(0.38, 0.02, 0.09, 1.66) all";
      case "Slow":
        return "1.2s cubic-bezier(0.38, 0.02, 0.09, 1.66) all";
      default:
        return "0.6s cubic-bezier(0.38, 0.02, 0.09, 1.66) all";
    }
  };
  function flipCard(index) {
    setSteps(steps + 0.5);
    if (steps % 1 === 0) {
      setCount(steps);
    }

    setOpenedCard((opened) => [...opened, index]);
  }

  useEffect(() => {
    if (openedCard < 2) return;

    const firstMatched = pairOfTurtles[openedCard[0]];
    const secondMatched = pairOfTurtles[openedCard[1]];

    if (secondMatched && firstMatched.id === secondMatched.id) {
      setMatched([...matched, firstMatched.id]);
    }

    if (openedCard.length === 2)
      setTimeout(() => {
        setOpenedCard([]);
      }, 500);
  }, [openedCard]);

  const Header = () => {
    switch (playVariant) {
      case 0:
        return (
          <MemoryGameHeader
            startGame={play}
            moves={count}
            successCards={matched}
          />
        );
      case 1:
        return <MultyplayerHeader />;
      default:
        break;
    }
  };

  useEffect(() => {
    if (playVariant === 1) {
      socket.on("getMove", (data) => {
        flipCard(data.index);
      });
    }
  }, [playVariant]);

  const flipWithSocket = (index) => {
    socket.emit("doingMove", {
      index: index,
      user: sessionStorage.currentUser,
      room: room[1],
    });
    flipCard(index);
  };

  const clickVariant = (index) => {
    if (playVariant === 1) {
      flipWithSocket(index);
    } else {
      flipCard(index);
    }
  };

  return (
    <div className={styles.container}>
      {/* <SmallModal open={open} setOpen={setOpen}/> */}
      <div className={styles.containerGrid}>
        {!play && (
          <div className={styles.backdrop}>
            <button onClick={() => setPlay(true)} className={styles.playButton}>
              Play
            </button>
          </div>
        )}
        {Header()}
        <div className="memory">
          <div className="memoryGame">
            <div className="cards">
              {pairOfTurtles &&
                pairOfTurtles.map((turtle, index) => {
                  let isFlipped = false;

                  if (openedCard.includes(index)) isFlipped = true;
                  if (matched.includes(turtle.id)) isFlipped = true;

                  return (
                    <div
                      className={`turtle-card ${isFlipped ? "flipped" : ""} `}
                      key={index}
                      onClick={() => !isFlipped && clickVariant(index)}
                    >
                      <div
                        className="inner"
                        style={{
                          transition: activeSpeedStyle(),
                        }}
                      >
                        <div
                          style={{
                            background:
                              isFlipped && matched.includes(turtle.id)
                                ? "green"
                                : isFlipped && openedCard.length === 1
                                ? "white"
                                : "red",
                          }}
                          className="front"
                        >
                          <h4
                            style={{
                              color:
                                isFlipped && matched.includes(turtle.id)
                                  ? "white"
                                  : isFlipped && openedCard.length === 1
                                  ? "black"
                                  : "white",
                            }}
                          >
                            {turtle.name}
                          </h4>
                        </div>
                        <div className="back"></div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <MemoryGameFooter
        setPlayVariant={setPlayVariant}
        changeSpeed={changeSpeed}
        setChangeSpeed={setChangeSpeed}
        chooseVoc={chooseVoc}
        setChooseVoc={setChooseVoc}
      />
    </div>
  );
}

export default MemoryGame;
